import React, { useEffect, useRef, useState } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';


import logo from '../../assets/static/logo-rocketit-neg-rgb.png'
import { Fade } from "react-reveal";

export default function Page(props) {

    const btnScrollTop = useRef(null);
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = (event) => {
        if (window.pageYOffset >= 700 && !showBtn) {
            setShowBtn(true);
        } else {
            setShowBtn(false);
        }
    }

    return (
        <ScrollableAnchor id={'home'}>
            <div className='flex topo w-full h-screen flex-wrap content-center justify-center'>
                <Fade top delay={250} duration={1000}>
                    <div>
                        <img src={logo} className='logotipo w-64 md:w-96 lg:w-auto' alt='Rocket It' />
                    </div>
                </Fade>

                <a href="#sobre" className='seta'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </a>

                {showBtn &&
                    <Fade up duration={500} ref={btnScrollTop}>
                        <a href="#home" className='back-top'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                            </svg>
                        </a>
                    </Fade>
                }
            </div>
        </ScrollableAnchor>
    )
}
