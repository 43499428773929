import React, { useRef, useState } from 'react';
import { Fade } from "react-reveal";
import ico_whats from '../../assets/icons/icon-whatsapp-branco.png'
import ScrollableAnchor from 'react-scrollable-anchor';
import InputMask from 'react-input-mask';

import { sendEmail } from '../../services/email.service';

const limitMin = 50;
const data = {
    name: '',
    email: '',
    phone: '',
    message: ''
}

export default function Page(props) {

    const [required] = useState(false)
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState(false);
    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errMessage, setErrMessage] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [countMin, setCountMin] = useState(limitMin);
    const [content, setContent] = useState(data)

    const refName = useRef(null);

    const validateInput = () => {
        let err = false;

        if (content.name.length < 2) {
            setErrName(true);
            err = true
        }
        if (content.phone.length < 15) {
            setErrPhone(true);
            err = true
        }
        if (content.message.length < 50) {
            setErrMessage(true);
            err = true
        }

        if (err) {
            setLoading(false);
            return false;
        }

        return true;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateInput()) return false;

        console.log('handleSubmit', content);
        const result = await sendEmail(content)
        console.log('handleSubmit', result);

        if (!result.error) {
            setComplete(true);
            setError(false);
            setContent(data);
        } else {
            setComplete(false);
            setError(true);
        }

        setLoading(false);
        return false;
    }

    const handleChange = (e) => {
        const value = e.target.value;
        const field = e.target.id;
        const min = e.target.minLength;

        if (value.length > min) {
            if (field === 'name') setErrName(false);
            if (field === 'phone') setErrPhone(false);
            if (field === 'message') setErrMessage(false);
        }

        if (field === 'message') setCountMin(value.length < limitMin ? limitMin - value.length : 0);

        setContent({ ...content, [field]: value });
    }

    const reset = () => {
        setContent(data);
        setError(false);
        setErrName(false);
        setErrEmail(false);
        setErrPhone(false);
        setErrMessage(false);
        refName.current.focus();
    }

    return (
        <div className='topo w-full px-10 py-16 lg:py-72 lg:px-20 2xl:px-52'>
            <ScrollableAnchor id={'contato'}>
                <div className='block 2xl:flex w-full content-center justify-center'>
                    <Fade left duration={2000}>
                        <div className='w-full lg:w-full 2xl:w-2/12'>
                            <div className='title font-lg'>
                                Fale com nosso<br />
                                <strong className='font-big family-bold'>Time</strong>
                            </div>
                        </div>
                    </Fade>
                    <Fade up duration={2000} delay={250}>
                        <div
                            className='w-full lg:w-full 2xl:w-8/12 mt-12 2xl:mt-0 ml-0 2xl:ml-32 family-thin line-height font-medium descricao'>
                            Para facilitar o contato e ter mais eficiência, atendemos por <strong>WhatsApp</strong>.
                            <br /><br />
                            <Fade left duration={2000} delay={500}>
                                <a
                                    href="https://api.whatsapp.com/send?phone=5515997987632&text=Ol%C3%A1,%20gostaria%20de%20conversar%20sobre%20meu%20projeto."
                                    alt="WhatsApp"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='transition duration-500 ease-in-out btn-red transform w-5/12 py-2 rounded-md family-bold uppercase flex justify-center items-center'>
                                    <img src={ico_whats} className='mr-4' /> WhatsApp
                                </a>
                            </Fade>
                            <br />
                            Se preferir, ligue para +55 <strong>(15) 99798-7632</strong>.
                            <br /><br />
                        </div>
                    </Fade>
                </div>
            </ScrollableAnchor>

            {/* <Fade up duration={2000}>
                <div className='flex w-full content-center justify-center'>
                    <div className='w-full 2xl:w-11/12 formulario p-4 lg:p-8 mt-20'>
                        <form className="flex flex-col justify-center" onSubmit={handleSubmit}>
                            <input
                                ref={refName}
                                id="name"
                                disabled={loading}
                                value={content.name}
                                className={`shadow-inner rounded-md mb-4 p-2 w-full family-medium font-dark ${errName ? 'bg-red-100 border border-red-700 text-red-700' : 'bg-gray-200'}`}
                                required={required}
                                type='text'
                                placeholder='Nome'
                                onChange={handleChange}
                                minLength={2}
                                tabIndex={1}
                            />

                            <input
                                disabled={loading}
                                value={content.email}
                                aria-errormessage={true}
                                className={`shadow-inner rounded-md mb-4 p-2 w-full family-medium font-dark ${errName ? 'bg-red-100 border border-red-700 text-red-700' : 'bg-gray-200'}`}
                                required={required}
                                id='email'
                                type='email'
                                onChange={e => setContent({...content, email: e.target.value})}
                                aria-label='email address' placeholder='E-mail'
                                tabIndex={2}
                            />

                            <InputMask
                                mask="(99) 99999-9999"
                                maskChar={null}
                                value={content.phone}
                                className={`shadow-inner rounded-md mb-4 p-2 w-full family-medium font-dark ${errPhone ? 'bg-red-100 border border-red-700 text-red-700' : 'bg-gray-200'}`}
                                required={required}
                                id='phone'
                                placeholder='Telefone'
                                onChange={handleChange}
                                tabIndex={3}
                            />

                            <textarea
                                disabled={loading}
                                value={content.message}
                                minLength={limitMin}
                                name="mensagem"
                                onChange={handleChange}
                                id="message"
                                rows="4"
                                cols="80"
                                className={`shadow-inner rounded-md mb-4 p-2 w-full family-medium font-dark ${errMessage ? 'bg-red-100 border border-red-700 text-red-700' : 'bg-gray-200'}`}
                                placeholder="Mensagem" required={required}
                                tabIndex={4}
                            />
                            <span
                                className='text-white mb-6 italic text-sm'>*Mínimo de {limitMin} caracteres... restam: <strong>{countMin}</strong></span>

                            <div className="flex flex-col-reverse md:flex-row justify-between w-full">
                                <button tabIndex={6} className="text-white w-full md:w-52 p-8 md:p-0 h-10 family-bold"
                                        type="reset"
                                        onClick={reset}>Cancelar
                                </button>

                                <button
                                    tabIndex={5}
                                    type="submit"
                                    disabled={loading}
                                    className="transition duration-500 ease-in-out btn-red transform w-5/12 py-2 rounded-md family-bold uppercase w-28"
                                >
                                    Enviar
                                </button>
                            </div>

                            {(errName || errPhone || errMessage) &&
                            <Fade top>
                                <div
                                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md w-4/5 mt-4 justify-between"
                                    role="alert">
                                    <strong className="font-bold">Alerta!</strong>
                                    <span
                                        className="block sm:inline ml-3">Preencha todos os campos corretamente.</span>
                                </div>
                            </Fade>
                            }

                            {complete &&
                            <Fade top>
                                <div
                                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-md w-4/5 mt-4 justify-between"
                                    role="alert">
                                    <strong className="font-bold">Sucesso!</strong>
                                    <span
                                        className="block sm:inline ml-3">Contato enviado com sucesso!</span>
                                </div>
                            </Fade>
                            }

                            {error &&
                            <Fade top>
                                <div
                                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md w-4/5 mt-4 justify-between"
                                    role="alert">
                                    <strong className="font-bold">ERRO!</strong>
                                    <span
                                        className="block sm:inline ml-3">Houve uma falha no envio, tente novamente.</span>
                                </div>
                            </Fade>
                            }

                            {loading &&
                            <Fade left>
                                <div className="mt-16 text-white">Aguarde, enviando seu contato...</div>
                            </Fade>
                            }
                        </form>
                    </div>
                </div>
            </Fade> */}
        </div>
    )
}
