import React from 'react';
import { configureAnchors } from 'react-scrollable-anchor'

import {Topo, Sobre, Jeito, Tecnologias, Numeros, Contato, Rodape} from '../../components'

const Page = () => {

    configureAnchors({offset: -60, scrollDuration: 2000})

    return <div>
        <Topo />
        <Sobre />
        <Jeito />
        <Tecnologias />
        <Numeros />
        <Contato />
        <Rodape />
    </div>
}

export default Page