import React from 'react';

import logo from '../../assets/icons/mascote-rocket-it.png'

export default function Page(props) {
    return (
        <div className='flex rodape w-full pt-48 pb-20 flex-wrap content-center justify-center text-center'>
            <div className='text-center'>
                <img src={logo} width='70%' className='inline mascote'/>
                <div className='mt-8'>
                    <strong className='mr-4'>Rocket It</strong> 2019 - 2024
                </div>
            </div>
        </div>
    )
}
